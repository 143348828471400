<template>
  <div class="horizontal-padding">
    <h1>Contact Us</h1>

    <alert
      v-if="formSuccess"
      variant="success"
    >
      Thanks for your message! We'll be in touch.
    </alert>

    <template v-else>
      <alert
        v-if="formErrors.non_field_errors !== undefined"
        variant="danger"
        class="js-form-error"
        dismissible
        @dismissed="formErrors = {}"
      >
        {{ formErrors.non_field_errors[0] }}
      </alert>

      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="subject">Subject</label>
          <input
            id="subject"
            v-model="formFields.subject"
            :class="{ 'is-invalid': formErrors.subject !== undefined }"
            type="text"
            maxlength="255"
            required
          >
          <div
            v-if="formErrors.subject !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.subject[0] }}
          </div>
        </div>

        <div class="form-group">
          <label for="message">Message</label>
          <textarea
            id="message"
            v-model="formFields.message"
            :class="{ 'is-invalid': formErrors.message !== undefined }"
            required
          />
          <div
            v-if="formErrors.message !== undefined"
            class="invalid-feedback js-form-error"
          >
            {{ formErrors.message[0] }}
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-primary"
          :disabled="formSubmitting"
        >
          <template v-if="formSubmitting">
            Sending Message
            <spinner />
          </template>

          <template v-else>
            Send Message
          </template>
        </button>
      </form>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    formFields: {
      subject: '',
      message: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'contact/',
        method: 'POST',
        json: this.formFields,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.formSuccess = true;
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>

<style scoped>
  form {
    margin: 0 auto 1rem;
    width: 290px;
  }
</style>
